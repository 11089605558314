import TokenService from './token.service'
import ApiInstance from './api'
import {enqueueSnackbar, VariantType} from 'notistack'
import {authActions} from '../store/slice/authSlice/authSlice'
import {InternalAxiosRequestConfig} from "axios";

const getMessage = (url: string = '/v1', status: number, data: Record<string, any> = {}) => {
    const responseMessage = data.details || data.detail || data.message

    return responseMessage || (
        url
        ? `При запросе ${url.split('/v1')[1]} произошла ошибка со статусом: ${status}`
        : 'Произошла непредвиденная серверная ошибка'
    )
}

export interface ConfigInterface extends InternalAxiosRequestConfig {
    token?: string,
}

export const AxiosInterceptorsSetup = (dispatch: any) => {
    ApiInstance.interceptors.request.use(
        (config: ConfigInterface) => {
            let token = TokenService.getAccessToken()
            const partnerId = TokenService.getAccessPartnerId()

            if (config?.token !== undefined)
            {
                token = config.token
            }

            config.headers['Authorization'] = `token ${token} ${partnerId}`

            return config
        },
        error => Promise.reject(error)
    )

    ApiInstance.interceptors.response.use(
        response => response,
        async error => {
            const {status = 500, data = {}} = error.response ?? {}
            const {responseURL} = error.request ?? {}

            let need_message = true;
            let message = getMessage(responseURL, status, data)
            let status_error = 0
            if (error?.response?.status) {
                status_error = error.response.status
            }

            let url = error?.config?.url
            if (url) {
                if (
                    (
                        (
                            url === "/order/create" ||
                            url === "/order/merge"
                        ) &&
                        status_error === 503
                    ) ||
                    (
                        url === "/user/messages" ||
                        new RegExp('^/auctions/[0-9]+$').test(url)
                    )
                ) {
                    need_message = false;
                } else if (status_error === 403) {
                     if (error?.response?.data) {
                        message = error.response.data
                     }
                     dispatch(authActions.logout())
                } else if (status_error === 400) {
                     if (error?.response?.data) {
                        message = error.response.data
                     } else {
                        need_message = false
                     }
                }

            }

            //const message = getMessage(responseURL, status, data)

            if (error.code !== 'ERR_CANCELED') {
                if (need_message && message) {

                    let variant: VariantType = 'error'
                    let preventDuplicate = false

                    if (message === 'Время сессии истекло, выполните вход повторно') {
                        variant = 'warning'
                        preventDuplicate = true
                    }

                    enqueueSnackbar(message, {
                        preventDuplicate: preventDuplicate,
                        variant: variant,
                        autoHideDuration: 40000,
                    })
                }

                if (status === 401) {
                    dispatch(authActions.logout())
                }
            }

            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject({
                status,
                url: responseURL,
                error,
                message,
            })
        }
    )
}
